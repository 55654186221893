import { SiteMutationSkyscraperLayout } from '@bbx/common/components/layouts/SiteMutationSkyscraperLayout'
import { StartPageUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { CdcLastViewedAdsForAllVerticals, UserFeed } from '@bbx/common/types/UserFeed'
import { feedErrorHandler, getAnonymousUserFeed, getUserFeed } from '@bbx/search-journey/common/api/cdcApiClient'
import { hardcodedErrorFeedLoggedIn, hardcodedErrorFeedLoggedOut } from '@bbx/search-journey/common/api/hardcodedErrorFeed'
import { getDmpSegmentsFromCookie } from '@bbx/search-journey/common/lib/getDmpSegmentsFromCookie'
import { StartpageSeoMetaTags } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/StartpageSeoMetaTags'
import { StartPageContainer } from '@bbx/search-journey/sub-domains/start-page/components/StartPageContainer'
import { getMetaTagInfo } from '@wh/common/chapter/api/tagging/metaTagApiClient'
import { MetaTagInfo } from '@wh/common/chapter/api/tagging/metaTagCache'
import { getUserInfo, getUUIDForVisitorUUID } from '@wh/common/chapter/api/userApiClient'
import { isAutoLoginCookieTrue } from '@wh/common/chapter/components/DataFetcher/DataFetcher'
import { isUserLoggedIn, useVerticalsInfo, VerticalInfoState } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { addBbxCookieToNextResponse } from '@wh/common/chapter/lib/cookies'
import { mapUserInfoToProfileData } from '@wh/common/chapter/lib/mapUserInfoToProfileData'
import { redirectTo } from '@wh/common/chapter/lib/routing/bbxRouter'
import { BbxCookie, getUniversalBbxCookie } from '@wh/common/chapter/types/cookies'
import { NextRequest, OptionalPageOptions, RequestWithIadVisitorCookie } from '@wh/common/chapter/types/nextJS'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next'
import { GetServerSideProps } from 'next/types'
import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'

interface StartPageProps {
    userFeed: UserFeed
    isJobsWidgetHidden: boolean
    metaTagInfo: MetaTagInfo | null
    profileData: UserProfileData | null
}

const START_PAGE_SEO_PAGE_NAME = '.'

const StartPage: NextPage<StartPageProps> & { Layout: typeof Layout } & {
    hasServerSidePropsWithUserData: boolean
} & OptionalPageOptions = ({ userFeed, metaTagInfo, isJobsWidgetHidden }) => {
    const [verticalsInfo] = useVerticalsInfo()
    return (
        <Fragment>
            <StartPageUserZoom />
            <StartPageContainer userFeed={userFeed} nrOfAdverts={sumNrOfAdverts(verticalsInfo)} isJobsWidgetHidden={isJobsWidgetHidden} />
            {metaTagInfo && (
                <StartpageSeoMetaTags
                    verticalId="BAP" // unused, does not matter
                    metaTagInfo={metaTagInfo}
                    seoPageName={START_PAGE_SEO_PAGE_NAME}
                />
            )}
        </Fragment>
    )
}

const sumNrOfAdverts = (verticalsInfo: VerticalInfoState) => {
    return verticalsInfo?.map((v) => v.nrOfAdverts).reduce((a, b) => a + b)
}

StartPage.hasServerSidePropsWithUserData = true

export const getServerSideProps: GetServerSideProps<StartPageProps> = async (
    context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<StartPageProps>> => {
    const { req, res, query } = context

    const profileData = mapUserInfoToProfileData(await getUserInfoOrUndefined(context.req))

    let userFeedPromise

    const metaTagInfoPromise = getMetaTagInfo(START_PAGE_SEO_PAGE_NAME, req)

    const lastViewedAdsAsString = getUniversalBbxCookie('bbxLastViewed', req) || '{}'
    const lastViewedAdsAsObject = JSON.parse(lastViewedAdsAsString) as CdcLastViewedAdsForAllVerticals

    const dmpSegmentIds = getDmpSegmentsFromCookie(req)

    if (isUserLoggedIn(profileData)) {
        userFeedPromise = feedErrorHandler(
            () => getUserFeed(profileData.uuid, { lastViewed: lastViewedAdsAsObject, dmpSegmentIds }, req, query),
            hardcodedErrorFeedLoggedIn,
        )
    } else {
        if (isAutoLoginCookieTrue(req) && !query.autoLoginAttempted) {
            const redirectTarget = '/iad?autoLoginAttempted=1'
            redirectTo(`/iad/myprofile/login?r=${escape(redirectTarget)}`, res)
            return {} as GetServerSidePropsResult<StartPageProps>
        }

        const iadVisitorCookie =
            getUniversalBbxCookie(BbxCookie.IADVISITOR, req) ?? (req as RequestWithIadVisitorCookie)?.iadVisitorCookie ?? uuidv4()

        userFeedPromise = feedErrorHandler(async () => {
            const anonymousUserData = await getUUIDForVisitorUUID(iadVisitorCookie, req)

            if (iadVisitorCookie !== anonymousUserData.uuid) {
                addBbxCookieToNextResponse(BbxCookie.IADVISITOR, anonymousUserData.uuid, 60 * 24 * 60 * 60, res)
            }

            // As soon as we will drop the mapping of Visitor ID to UUID we can remove the upper code and just call
            // getAnonymousUserFeed with the iadVisitorCookie (instead of anonymousUserData.uuid).

            return getAnonymousUserFeed(anonymousUserData.uuid, { lastViewed: lastViewedAdsAsObject, dmpSegmentIds }, req)
        }, hardcodedErrorFeedLoggedOut)
    }

    const [userFeed, metaTagInfo] = await Promise.all([userFeedPromise, metaTagInfoPromise?.catch(() => undefined)])

    return {
        props: {
            userFeed,
            isJobsWidgetHidden: isJobsWidgetHidden(req),
            metaTagInfo: metaTagInfo ?? null,
            profileData: profileData ?? null,
        },
    }
}

const isJobsWidgetHidden = (request?: NextRequest | undefined) => {
    const jobsWidgetHiddenCookie = getUniversalBbxCookie('bbxHideJobsStartpageWidget', request)
    return jobsWidgetHiddenCookie === 'true'
}

const getUserInfoOrUndefined = async (request?: NextRequest) => {
    try {
        return await getUserInfo(request)
    } catch (_error) {
        return undefined
    }
}

StartPage.Layout = SiteMutationSkyscraperLayout
StartPage.verticalLinksType = 'normal'

export default StartPage
